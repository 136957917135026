@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.my-feed {
  margin-left: 300px;
  margin-right: 50px;
}

.search-bar {
  margin-top: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: #0a4089;
  height: 80px;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header li,
.header a,
.header button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f5f5f5;
  text-decoration: none;
}

.header #hamburger {
  display: none;
}

@media screen and (max-width: 900px) {
    .header #link {
      display: none;
    }

    .header #hamburger {
      display: inline-flex;
      cursor: pointer;
    }
}

.dropdown-menu {
  position: absolute;
  list-style: none; 
  background: rgba(255, 178, 8, 1);
  transform: translateX(-20px);
  width: 250px;
  padding: 5px;
}

.dropdown-menu ul li a {
  display: block;
  width: 100%;
  line-height: 40px;
  padding: 5px;
}

.logo {
  font-family: "League Spartan", sans-serif;
  color: #fff;
  cursor: pointer;
}

.header ul {
  list-style: none;
}

.header ul li {
  display: inline-block;
  padding: 0px 20px;
}

.header ul li a {
  transition: all 0.3s ease 0s;
}

.header ul li a:hover {
  color: #ffb208;
}

.header #hamburger {
  padding: 0px; 
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  cursor: pointer;
}

.dropdown-menu ul li a:hover {
  color: #545454;
}

.header button {
  padding: 9px 25px;
  background-color: rgba(255, 178, 8, 1);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.header button:hover {
  background-color: rgba(255, 178, 8, 0.8);
}

.sidebar {
  position: fixed;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: #fff;
  transition: all 0.5s ease;
}

.sidebar #icon {
  color: #707070;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.sidebar #title {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #707070;
  transition: all 0.3s ease;
  white-space: nowrap;
  opacity: 1;
}

.sidebar .text {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #707070;
  transition: all 0.3s ease;
  white-space: nowrap;
  opacity: 1;
}

.sidebar .avatar {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
  cursor: default;
  margin-bottom: 20px;
}

.sidebar header .image-text .header-text {
  display: flex;
  flex-direction: column;
}

.sidebar .header-text .username {
  font-weight: 600;
  text-align: left;
}

.sidebar .header-text .faculty {
  margin-top: -2px;
  text-align: left;
}

.sidebar header {
  position: relative;
  cursor: pointer;
  margin-top: 20px;
}

.sidebar-list {
  margin-top: 60px;
}

.sidebar ul li a {
  height: 50px;
  margin-top: 20px;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.sidebar ul li a:hover {
  background: #0a4089;
}

.sidebar ul li a:hover #icon,
.sidebar ul li a:hover #title {
  color: #fff;
}

.form-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: #072f64;
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.form-sidebar {
  position: fixed;
  margin-top: 70px;
  height: 100%;
  width: 300px;
  padding: 10px 14px;
  background: #e8ebf8;
}

.form-sidebar .text {
  font-size: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #6c749d;
  text-align: left;
  transform: translateY(1px);
}

.form-sidebar ul li {
  height: 50px;
  list-style: none;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.form-sidebar ul .selected {
  background: #bbc4ea;
}

.form-sidebar ul li:hover {
  background: #bbc4ea;
}

.form-sidebar .header-text {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-sidebar .forms-list {
  margin-top: 10px;
}

.form-sidebar #icon {
  color: #626a93;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-sidebar .subtext {
  font-weight: 500;
}

.form-sidebar .item-text {
  color: #52597b;
}

.form-sidebar hr {
  background-color: #d6d8e4;
  height: 1.5px;
  border: none;
  margin-top: 25px;
  margin-bottom: 20px;
}

.dash-sidebar {
  position: fixed;
  height: 50%;
  width: 300px;
  padding: 10px 14px;
  background: #e8ebf8;
}

.dash-sidebar .text {
  font-size: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #6c749d;
  text-align: left;
  transform: translateY(1px);
}

.dash-sidebar ul li {
  height: 50px;
  list-style: none;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dash-sidebar .stats-list {
  margin-top: 10px;
}

.dash-sidebar .header-text {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.survey-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ffa500, #ff6347);
  transform: translateY(-130px);
  position: fixed;
  width: 100%;
  z-index: 1;
}

.survey-navbar .section-header {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  color: #f5f5f5;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 20px;
  cursor: pointer;
}

.survey-navbar .selected {
  color: white;
  background-color: #ffc14d;
}

.view-forms {
  margin-top: 100px;
  margin-left: 300px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 70px;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.dashboard-heading {
  font-size: 2rem;
  font-weight: 700;
  padding: 50px;
  background: white;
  text-align: left;
}

.surveys {
  display: block;
  margin-top: 30px;
}

.survey {
  border-radius: 8px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  padding: 20px 30px;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.survey:hover {
  background-color: #e8ebf8;
}

.survey .checkbox {
  text-align: left;
  padding-right: 20px;
}

.survey .favourite {
  width: 20px;
  padding-right: 30px;
  color: #707070;
}

.survey .favourite:hover {
  color: #52597b;
  transition: all 0.2s ease;
}

.survey .survey-info {
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

.survey .title,
.survey .subtitle {
  line-height: 1.5;
  text-align: left;
  padding-left: 5%;
}

.survey .title {
  font-size: 16px;
  font-weight: 500;
}

.survey .subtitle {
  font-size: 14px;
}

.survey .publishTag {
  cursor: default;
  border: none;
  background: none;
}

.survey .close {
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
  padding-left: 30px;
  color: #707070;
  transition: all 0.2s ease;
}

.survey .close:hover {
  color: #52597b;
}

.feed {
  background: #e8ebf8;
}

.feed:hover {
  background: #bbc4ea;
}

.surveyStart {
  margin-top: 130px;
}

.details {
  width: 70%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
}

.form,
.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  padding-top: 10px;
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-surveys {
  margin: 15%;
}

.no-surveys .text {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #52597b;
}

.no-surveys .subtext {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #626a93;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.sticky-row {
  position: sticky;
  top: 0;
}

.table-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.minimized {
  display: none;
}

@media screen and (max-width: 1100px) {
  .expanded{
    display: none;
  }

  .minimized {
    display: block;
  }
}

.search-bar .user-search {
  z-index: 100;
  position: absolute;
  margin-top: 48px;
  width: 1500px;
  max-width: calc(100% - 350px);
}

.accordion {
  background-color: #ffffff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}
